/* special selector used to split the final output file in order to isolate customisations - do not delete this selector */
php.css-split-selector {
  display: none;
}

body {
  font-family: $sans-serif;
  font-weight: 300;
  color: $text-colour;
  background-color: $white;
  font-size: 16px;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $sans-serif-alt;
  font-weight: 600;
}

h1,
.h1,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: var(--brand-primary);
}

.page-header {
  margin-top: 20px;
  margin-bottom: 20px;

  h1 {
    color: var(--brand-accent);
    text-transform: uppercase;
    font-size: 25px;
  }
}

.h2,
h2 {
  color: var(--brand-accent);
  font-size: 25px;
}

h1,
.h1 {
  margin-top: 0;
}

.special-padding {
  padding-top: 30px;
  padding-bottom: 30px;
}

blockquote {
  border-left: medium none;
  border-top: 1px solid $grey;
  border-bottom: 1px solid $grey;
  color: var(--brand-primary);
  font-family: $sans-serif-alt;
  line-height: 1.3;
  margin: 15px 30px 15px 0;
  padding: 10px 5px;

  h1 {
    font-size: 1.5em;
    padding: 0;
  }

  h2 {
    font-size: 1.2em;
  }

  h3 {
    font-size: 1em;
  }
}

div[style*="url("] {

  .bg-transparent,
  h1,
  h2,
  h3,
  h4 {
    color: white;
  }
}

a {
  color: var(--text-link-colour);

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.bg-transparent {
  background-color: transparent;
  color: $text-colour;

  a,
  a:visited {
    color: var(--text-link-colour);
  }

  .bg-colour-pad {
    padding: 0px;
  }

  .btn.btn-default,
  .gform_body .button {
    color: white;
    background-color: var(--text-link-colour);
    border-color: white;
  }
}

.well {
  border-color: transparent;
  background-color: transparent;
  border-radius: 0;

  > h3:first-of-type {
    margin-top: 0;
  }

  > ul {
    padding-left: 1.5rem;
  }
}

.bg-brand-primary {
  background-color: var(--brand-primary);
  color: white;

  a,
  a:visited {
    color: var(--brand-accent);
  }

  .container,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
  }

  .form-control {
    border: 1px solid white;
    color: white;
  }

  .btn.btn-default,
  .gform_body .button {
    color: white;
    background-color: transparent;
    border-color: white;
  }
}

.bg-brand-primary-alt {
  background-color: var(--brand-primary-alt);
  color: white;

  a,
  a:visited {
    color: var(--brand-accent);
  }

  .container,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
  }

  .form-control {
    border: 1px solid white;
    color: white;
  }

  .btn.btn-default,
  .gform_body .button {
    color: white;
    background-color: transparent;
    border-color: white;
  }
}

.bg-brand-accent {
  background-color: var(--brand-accent);
  color: white;

  a,
  a:visited {
    color: white;
  }

  .container,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: white;
  }

  .form-control {
    border: 1px solid white;
    color: white;
  }

  .btn.btn-default,
  .gform_body .button {
    color: white;
    background-color: transparent;
    border-color: white;
  }
}

.bg-light-grey {
  background-color: $light-grey;
  color: $text-colour;

  a,
  a:visited {
    color: var(--brand-accent);
  }

  .container,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--brand-primary-alt);

    a,
    a:visited {
      color: var(--brand-primary);
    }
  }

  &.active:focus,
  .form-control {
    border: 1px solid white;
    color: $text-colour;
  }

  .btn.btn-default,
  .gform_body .button {
    color: black;
    background-color: transparent;
    border-color: black;
  }
}

.bg-grey {
  background-color: $grey;
  color: white;

  a,
  a:visited {
    color: white;
  }

  .form-control {
    border: 1px solid white;
    color: white;
  }

  .btn.btn-default,
  .gform_body .button {
    color: white;
    background-color: transparent;
    border-color: white;
  }
}

.bg-white {
  background-color: white;
  color: $text-colour;

  a,
  a:visited {
    color: var(--brand-accent);
  }

  .container,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: var(--brand-primary-alt);

    a,
    a:visited {
      color: var(--brand-primary);
    }
  }

  &.active:focus,
  .form-control {
    border: 1px solid white;
    color: $text-colour;
  }

  .btn.btn-default,
  .gform_body .button {
    color: white;
    background-color: var(--brand-accent);
    border-color: white;
  }
}

.btn {
  border-radius: 0;
  text-transform: uppercase;
  font-weight: 600;
  position: relative;
  text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
  white-space: pre-line;
}

.btn-transparent {
  @extend .bg-transparent;
  color: white !important;
  border-color: rgba(255, 255, 255, 0.5);

  &:active,
  &:hover {
    border-color: white !important;
    color: white;
  }
}

.btn-brand-primary,
.btn-primary {
  @extend .bg-brand-primary;
  color: white !important;
  border-color: rgba(255, 255, 255, 0.5);

  &:active,
  &:hover {
    background-color: var(--brand-primary);
    border-color: white !important;
    color: white;
  }
}

.btn-brand-primary-alt,
.btn-primary-alt {
  @extend .bg-brand-primary-alt;
  color: white !important;
  border-color: rgba(255, 255, 255, 0.5);

  &:active,
  &:hover {
    background-color: var(--brand-primary-alt);
    border-color: white !important;
    color: white;
  }
}

.btn-brand-accent {
  @extend .bg-brand-accent;
  color: white !important;
  border-color: rgba(255, 255, 255, 0.5);

  &:active,
  &:hover {
    border-color: white !important;
    color: white;
  }
}

.btn-light-grey {
  @extend .bg-light-grey;
  color: $text-colour !important;

  &:active,
  &:hover {
    border-color: $text-colour !important;
    color: white;
  }
}

.btn-grey {
  @extend .bg-grey;
  color: white !important;

  &:active,
  &:hover {
    border-color: white !important;
    color: white;
  }
}

.btn-white {
  @extend .bg-white;
  color: $text-colour !important;

  &:active,
  &:hover {
    border-color: $text-colour !important;
    color: white;
  }
}

div[style*="url("] {
  .btn {
    border-color: transparent;
  }
}

.jumptop {
  position: absolute;
  top: 0;
  left: 0;
}

.brand {
  display: inline-block;
  margin: 7px 0;
  width: 100px;

  img {
    display: block;
  }
}

.panzoom-focal {
  border: 2px dotted rgba(0, 0, 0, 0.25);
  padding: 15px;

  .panzoom-reset {
    position: absolute;
    top: 0;
    left: 0;
  }
}

nav,
.nav,
.ac_input {
  font-family: $sans-serif-alt;
}

.navbar,
.splash {
  margin-bottom: 0;
  border: 0px none;
  min-height: auto;
  position: relative;
}

.splash,
.navigation {
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
}

.splash[style*="url("] {
  background-size: cover;
  background-position: 50% 37.5%;
}

.splash,
.splash .carousel-inner {
  min-height: 47.5vh;
  z-index: 5;
}

.admin-bar {

  .splash,
  .splash .carousel-inner {
    min-height: 47.5vh;
  }
}

.home {

  .splash,
  .splash .carousel-inner {
    min-height: 55vh;
  }
}

.navigation {
  z-index: 100;

  .navbar {
    border: 0px none;
  }
}

.navbar-xs {
  align-content: center;
  display: flex;
  justify-content: space-between;
  min-height: 65px;
  padding-left: 15px;
  position: relative;
  background-color: var(--brand-primary);

  .navbar-toggle {
    border-radius: 0;
    border-color: white;
    padding: 6px 10px;
    height: 45px;

    .icn-bar {
      border-radius: 1px;
      display: block;
      height: 2px;
      width: 22px;
      margin: 4px 0;
      background-color: white;
    }

    &:hover {
      background-color: var(--brand-accent);

      .icn-bar {
        background-color: $white;
      }
    }
  }

  .brand {
    img {
      border: 10px solid white;
    }
  }
}

.navbar-apex {
  background-color: var(--brand-primary);
  font-size: 13px;

  .nav {
    float: left;
    text-transform: uppercase;

    >li {
      >a {
        padding-bottom: 10px;
        padding-top: 10px;
      }
    }
  }

  .navbar-form {
    float: right;
    margin: 6px 0;
    padding: 0 5px;

    input {
      background-color: transparent;
      border: 1px solid rgba(255, 255, 255, 0.1);
      color: white;
      height: 28px;
      padding: 0 0 0 10px;
      width: 75px;
      transition: all 1s;

      &:focus {
        width: 150px;
        background-color: white;
        color: $text-colour;
        border: 1px solid rgba(255, 255, 255, 0.25);
      }
    }

    .form-control::-moz-placeholder,
    .form-control::-webkit-input-placeholder,
    .form-control::-ms-input-placeholder {
      color: white !important;
    }

    .btn {
      padding-top: 3px;
      padding-bottom: 3px;
      color: white;
    }
  }
}

.navbar-apex {
  .navbar-nav {
    .menu-item-has-children {
      >a::before {
        content: "";
        display: inline-block;
        font-family: fontawesome;
        font-weight: 100;
        padding-right: 10px;
      }
    }
  }
}

/* PRIMARY NAV */
.dropdown-menu {
  border: 0 !important;
  box-shadow: none !important;
  padding: 0;
}

.navbar-primary {
  .navbar-nav {
    .dropdown-menu {
      background-color: var(--brand-accent);
      font-size: 1em;

      >li,
      li.active {
        a {
          padding: 7px 15px;
          color: white;

          &:hover,
          &:focus {
            background-color: var(--brand-primary);
            color: white;
            text-decoration: none;
            background-image: none;
          }
        }
      }
    }
  }

  .nav {
    >li {
      >a {
        text-transform: uppercase;
        color: white;

        &:focus,
        &:hover {
          background-color: var(--brand-accent);
          text-decoration: none;
        }
      }
    }

    .open {

      >a,
      &:focus,
      &:hover {
        background-color: var(--brand-accent);
        color: white;
        text-decoration: none;
      }
    }
  }

  &.navbar-collapse {
    background-color: var(--brand-primary-dark-rgba);
  }
}

/* apex nav */
.navbar-apex {
  .nav {
    .open {
      .dropdown-menu {
        background-color: var(--brand-primary-alt);
        color: $white;
      }
    }

    >li>a {
      color: white;
      text-shadow: none;
    }

    >li>a:hover,
    .open>a:hover {
      background-color: $white;
      color: var(--brand-primary-alt);
      text-decoration: none;
    }

    >li>a:focus,
    .open>a,
    .open>a:focus {
      background-color: var(--brand-primary-alt);
      color: $white;
      text-decoration: none;
    }
  }

  .dropdown-menu {
    border: 0 !important;
    padding: 0;
    background-color: var(--brand-primary-alt);

    >li>a {
      padding: 10px 15px;
      color: $white;

      &:focus,
      &:hover {
        background-color: $white;
        color: $black;
        text-decoration: none;
        background-image: none;
      }
    }
  }
}

.social-links {
  i {
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    font-size: 18px;
    height: 30px;
    line-height: 30px;
    margin: 5px 2px;
    text-align: center;
    width: 30px;
    color: var(--brand-accent);
  }
}


/* CAROUSEL */
.carousel-fade {
  .carousel-inner {
    >.item {
      opacity: 0;
      top: 0;
      left: 0;
      width: 100%;
      display: block;
      position: absolute;
      z-index: 0;
      transition: none;
      transform: translate3d(0, 0, 0) !important;
      right: 0;
      bottom: 0;
    }

    >.active {
      opacity: 1;
      z-index: 3;
    }

    >.next.left,
    >.prev.right {
      transition: opacity 0.7s ease-in;
      opacity: 1;
      left: 0;
      z-index: 2;
    }

    >.active.left,
    >.active.right {
      z-index: 1;
    }
  }

  .carousel-control {
    z-index: 4;
    height: 160px;
    bottom: auto;
    top: calc(50% - 80px);
  }
}

.carousel {

  .carousel-control.left,
  .carousel-control.right {
    background-image: none !important;
    text-shadow: none;
    z-index: 50;
    width: 8vw;
  }

  .carousel-control::after {
    color: rgba(255, 255, 255, 0.2);
    display: block;
    font-family: fontawesome;
    font-size: 100px;
    line-height: 1;
    position: absolute;
    top: calc(50% - 50px);
  }

  .right.carousel-control::after {
    content: "";
    right: 10%;
  }

  .left.carousel-control::after {
    content: "";
    left: 10%;
  }

  .carousel-indicators {
    li {
      border: 0px none;
      background: rgba(white, 1);

      &.active {
        height: 10px;
        width: 10px;
        background: var(--brand-accent);
        margin: 1px;
      }
    }
  }
}

.archive {
  .post-image {
    width: 100%;
    display: block;
    background-size: cover;
    background-position: center center;
  }
}

.banner {
  margin-bottom: -1px;

  .carousel {
    @extend .carousel-fade;

    .carousel-inner {
      .item {
        background-position: 50% 45%;
        background-repeat: no-repeat;
        background-size: cover;

        .carousel-caption {
          align-content: center;
          color: #fff;
          display: flex;
          flex-flow: column nowrap;
          justify-content: flex-end;
          left: 5vw;
          right: 5vw;
          top: 0;
          text-align: left;

          >div {
            margin-bottom: 30px;
          }

          .btn {
            color: white;
            border-color: white;
            background-color: rgba(black, 0.1);
          }

          h1 {
            font-family: $sans-serif;
            font-size: 30px;
            font-weight: 500;
            margin-bottom: 20px;
          }

          .carousel-description {
            margin-bottom: 40px;
          }

          h1,
          p {
            padding: 0;
            text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
          }
        }
      }
    }
  }
}

.project-list {
  ul {
    display: flex;
    flex-flow: row wrap;
    list-style: outside none none;
    padding: 0;

    li {
      background-color: rgba(0, 0, 0, 0.5);
      border-radius: 5px;
      flex: 1 1 auto;
      font-family: PT Sans;
      font-size: 1.2em;
      font-weight: 600;
      margin: 0 10px 10px 0;
      padding: 5px 12px;
      position: relative;
      min-width: 20%;

      small {
        display: block;
        font-weight: 200;
      }

      a {
        color: white;
        display: block;

        &:hover {
          text-decoration: none;

          &:after {
            content: "";
            display: block;
            font-family: fontawesome;
            font-size: 14px;
            font-weight: 100;
            height: 12px;
            line-height: 12px;
            position: absolute;
            right: 3px;
            top: 3px;
            width: 12px;
          }
        }
      }

      &:hover {
        background-color: white;

        a {
          color: var(--brand-primary);
        }
      }
    }
  }
}

.p-3 {
  padding: 1.25rem;
}

input:focus, input:active{
  outline: none;
}
a, a:active, a:focus {
   outline: none;
}

.single-project,
.single-place {
  .map-block {
    min-height: 55vh;
    position: relative;

    .flex-map {
      position: absolute !important;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .gallery-block {
    .carousel {
      margin-top: 30px;

      .carousel-inner .item {
        min-height: 65vh;
      }
    }
  }

  .partners-block,
  .gallery-block,
  .news-block {

    h2,
    h3 {
      color: var(--brand-accent);
      text-transform: uppercase;
      text-align: center;
    }

    .post-text {

      h2,
      h3 {
        text-align: left
      }
    }

    h3 {
      font-size: 18px;
    }

    img {
      max-width: 100%;
    }
  }

  .partners-block {
    .col-md-6 {
      padding: 30px 15px;
    }
  }

  .partner-logos {
    display: flex;
    margin: 30px 0;
    flex-flow: row wrap;
    justify-content: start;
    align-items: center;
    align-content: center;

    a {
      background-origin: content-box;
      background-position: 50% center;
      background-repeat: no-repeat;
      background-size: contain;
      box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.2);
      flex: 0 1 auto;
      height: 120px;
      margin: 15px 15px 0 0;
      padding: 10px;
      width: 150px;
    }
  }
}


/* MAIN SEARCH */
.main-search-container {
  bottom: 5vh;
  display: block;
  margin: 0 auto;
  position: absolute;
  width: 100%;
  z-index: 999;

  .main-search {
    display: block;
    margin: 0 auto;
    max-width: 380px;
    position: relative;
    padding: 0 10px;

    .btn {
      background: #fff none repeat scroll 0 0;
      border-left: 1px dotted #e4e4e4;
      padding: 8px 11px;
      position: absolute;
      right: 11px;
      top: 1px;
    }

    input {
      border: 1px solid #fff;
      box-shadow: none;
      font-size: 1em;
      height: 40px;
      padding: 8px 12px;
      color: #000;

      &.tt-hint {
        color: #999;
      }
    }
  }
}

/* WordPress Gallery
------------------------------------------ */

.gallery {
  margin-bottom: 20px;

  &:after {
    display: table;
    content: "";
    clear: both;
  }
}

.gallery-item {
  display: inline-block;
  padding: 5px;

  .gallery-icon {
    border: 1px solid #e3e3e3;
    text-align: center;
  }

  img {
    max-width: 100%;
    height: auto;
  }
}

.gallery-columns-1 .gallery-item {
  width: 100%;
  float: none;
}

.gallery-columns-2 .gallery-item {
  width: calc(50% - 10px);
}

.gallery-columns-3 .gallery-item {
  width: calc(33.3333% - 10px);
}

.gallery-columns-4 .gallery-item {
  width: calc(25% - 10px);
}

.gallery-columns-5 .gallery-item {
  width: calc(20% - 10px);
}

.gallery-columns-6 .gallery-item {
  width: calc(16.6666% - 10px);
}

.gallery-columns-7 .gallery-item {
  width: calc(14.27% - 10px);
}

.gallery-columns-8 .gallery-item {
  width: calc(12.5% - 10px);
}

.gallery-columns-9 .gallery-item {
  width: calc(11.10% - 10px);
}

.gallery-columns-2 .gallery-item:nth-child(2n+1),
.gallery-columns-3 .gallery-item:nth-child(3n+1),
.gallery-columns-4 .gallery-item:nth-child(4n+1),
.gallery-columns-5 .gallery-item:nth-child(5n+1),
.gallery-columns-6 .gallery-item:nth-child(6n+1),
.gallery-columns-7 .gallery-item:nth-child(7n+1),
.gallery-columns-8 .gallery-item:nth-child(8n+1),
.gallery-columns-9 .gallery-item:nth-child(9n+1) {
  clear: left;
}

/**
 * content blocks
 */
.content-blocks {

  /*
  > div.container:first-child{
      margin-top: 40px;
  }
  */
  .alignleft,
  .alignright {
    @extend .img-responsive;
  }

  >div.full-width:first-child {
    .content-block.panel_grid:first-child {
      margin-top: 0;
    }
  }

  .full_width_image {
    div[style*="url("] {
      position: relative;
      height: 45vh;
      background-size: cover;
      background-position: 50% 45%;
      transition: 0s linear;
      transition-property: background-position;
    }
  }

  .lead-intro {
    padding-top: 15px;

    p:first-of-type {
      font-size: 1.35em;
      font-weight: 300;
    }

    .woocommerce {
      padding-top: 20px;
    }
  }

  .main-width {
    img {
      @extend .img-responsive;
    }

    .call-out {
      @extend .col-md-4;
      background-color: transparent;
      position: absolute;
      top: 0;
      right: -33%;

      h4 {
        color: $text-colour;
      }
    }
  }

  .full-width {
    max-width: 100vw;

    .panel_grid {
      .tab-pane.active {
        padding: 0;
      }
    }
  }

  .full-width:last-child {
    margin-bottom: 0;
  }

  .expanding_text {
    border-bottom: 1px solid grey;
    border-top: 1px solid grey;
    padding-bottom: 20px;

    .btn.btn-text {
      text-transform: uppercase;
      padding-left: 0;
      font-size: 0.9em;
      text-shadow: none;

      &:after {
        font-family: 'fontawesome';
        padding-left: 10px;
        content: "";
      }

      &.collapsed:after {
        content: "";
      }
    }
  }

  .expanding_text,
  .main-width .text {
    padding-left: 15px;
    padding-right: 15px;
  }

  .full_width_image {
    div[style*="url("] {
      position: relative;

      .btn-photo-info {
        display: none;
      }
    }
  }

  .carousel {
    @extend .carousel-fade;

    .carousel-inner {
      min-height: 400px; // formerly 55vh

      .item {
        background-position: 50% 45%;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: 400px; // formerly 55vh

        .carousel-caption {
          text-align: left;
          right: auto;
          left: 15%;
          top: 0px;
          color: white;

          .btn {
            color: white;
            border-color: white;
            background-color: rgba(black, 0.1);
          }

          h1 {
            text-transform: uppercase;
            font-weight: 500;
            font-size: 30px;
            margin-bottom: 20px;
          }

          p {
            font-size: 1.35em;
          }

          .carousel-description {
            margin-bottom: 40px;
          }

          h1,
          p {
            padding: 0;
            text-shadow: 1px 1px 4px #000;
          }
        }

        .feature_panel {
          .columns {
            min-height: 55vh;
          }
        }
      }
    }

    .carousel-indicators {
      left: auto;
      text-align: right;
      right: 4%;
      bottom: 15px;
    }

    .show-thumbnails {
      margin-left: auto;
      margin-right: auto;

      .carousel-inner {
        height: 80vh;
        max-height: 1000px;

        .item {
          .carousel-caption {
            background-color: rgba(0, 0, 0, 0.75);
            bottom: 0;
            color: #fff;
            left: 0;
            padding: 15px 30px;
            right: 0;
            text-align: left;
            top: auto;
            width: 100%;
          }
        }
      }

      .carousel-thumbnails {
        display: flex;
        flex-flow: row wrap;
        align-content: space-between;
        justify-content: flex-start;

        >a {
          flex: 1 1 auto;
          margin: 10px 10px 0 0;
          max-width: 20%;

          .thumbnail {
            margin-bottom: 0;
            min-height: 17.5vh;
            border-radius: 0;
            border: 0px none;
            background-size: cover;
            background-position: center center;
          }
        }
      }
    }
  }

  .panel_grid {
    .panel-group {
      margin-bottom: 0;

      .panel {
        box-shadow: none;
      }

      .panel-heading {
        .panel-title {
          >a {
            display: block;

            &:after {
              font-family: 'fontawesome';
              float: right;
              content: "";
            }

            &.collapsed:after {
              content: "";
            }
          }
        }
      }
    }

    .tab-pane.active {
      padding: 15px;
      margin-bottom: 20px;
      border-left: 1px solid #ddd;
      border-right: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
    }

    .nav-tabs {
      >li {
        >a {
          font-weight: 600;
          border-left: 1px solid #ddd;
          border-right: 1px solid #ddd;
          border-top: 1px solid #ddd;
          border-radius: 0;
          color: $grey;
          margin-right: 4px;
        }

        &.active {

          >a,
          a:hover,
          a:focus {
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
            border-top: 1px solid #ddd;
            border-radius: 0;
            color: $grey;
          }
        }
      }
    }
  }

  .call_to_action {
    .bg-overlay {
      background-color: rgba(0, 0, 0, 0.35);
      background-blend-mode: darken;
    }

    div[style*="url("] {
      background-position: center 35%;
      background-size: cover;
    }

    .cta-blocks {
      flex-direction: column;
      display: flex;
      flex: 1 1 auto;
      justify-content: stretch;

      &.cta-individual {
        .cta-content {
          margin-bottom: 10px;
          background-size: cover;
          background-repeat: no-repeat;
          min-height: 400px;
        }

        div[style*="url("] {
          justify-content: flex-end;

          h2 {
            padding-bottom: 10px;
          }

          .block-buttons {
            padding-bottom: 20px;
          }
        }
      }

      .cta-split {
        display: flex;
        flex-flow: column nowrap;

        .cta-split-image {
          height: 50%;
          flex: 1 1 60%;
          width: 100%;
        }

        .cta-text-copy {
          padding: 15px 3vw;
          flex: 0 0 auto;
        }
      }

      .cta-content {
        min-height: 55vh;
        display: flex;
        flex-direction: column;

        p,
        dt {
          font-weight: 300;
          font-size: 1.1em;
        }

        dt {
          font-weight: 500;
          padding: .25em 0;
        }

        .cta-text-copy {
          /*padding: 0 15px;*/

          h1,
          .h1,
          h2,
          .h2 {
            margin-bottom: 30px;

            &:first-of-type {
              margin-top: 0;
            }
          }

          .block-buttons {
            margin-top: 30px;
          }

          .post-list {
            padding-bottom: 20px;
            border-bottom: 1px dotted rgba(255, 255, 255, 0.5);
          }
        }

        .text-left {
          .btn {
            margin: 0 15px 0 0;
          }
        }

        .text-left {
          .btn {
            margin: 0 0 0 15px;
          }
        }

        .text-center {
          .btn {
            margin: 0 15px;
          }
        }

        .block-buttons {
          margin-top: 0;
        }

        .col-md-3,
        .col-md-4,
        .col-md-6,
        .col-md-8,
        .col-md-9,
        .col-md-12 {
          padding: 30px 5vw;
        }

        .cta-icon {
          min-height: 10vh;
          background-repeat: no-repeat;
          background-position: center center;
          background-size: contain;
        }

        &.cta-split {
          padding: 0;
        }
      }

      &.cta-common {
        .block-buttons {
          margin-top: 15px;
        }

        .cta-content.col-md-12 {
          h2 {
            text-align: center;
            text-transform: uppercase;
            color: var(--brand-accent);
            text-shadow: 0 0 9px rgba(0, 0, 0, 0.9);
          }

          p {
            font-size: 40px;
            max-width: 700px;
            line-height: 1.2;
            font-weight: 700;
            color: white;
            text-shadow: 0 0 10px rgba(0, 0, 0, 0.9);
          }

          min-height: 55vh;
        }
      }
    }
  }

  .listings_grid {
    .listing-grid {
      display: flex;
      flex-flow: column wrap;
      justify-content: flex-end;
      align-items: stretch;

      >div {
        min-height: 55vh;
        flex: 1 1 auto;
        margin: 0 5px 5px 0;
      }

      .listing-item {
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
        position: relative;

        >a {
          bottom: 0;
          display: flex;
          justify-content: center;
          align-content: center;
          flex-flow: column;
          left: 0;
          position: absolute;
          right: 0;
          top: 0;
          background-color: rgba(0, 0, 0, 0.5);
          transition: all 0.5s;

          &.lead {
            font-weight: 600;
            color: white;
            margin: 0;
            text-shadow: 0 0 4px black;

            &:hover {
              background-color: unset;
              transition: all 0.5s;
              text-decoration: none !important;
              text-shadow: 0 0 15px #000, 0 0 2px #000;
            }
          }
        }

        &[style*="url("] {
          background-position: 50% 45%;
          background-size: cover;
          background-repeat: no-repeat;
        }

        &.listing-item-text {
          align-items: flex-start;
          flex-direction: column;
          justify-content: flex-start;
          text-align: left;

          img {
            max-width: 40%;
            display: inline-block;
            padding: 10px 0 10px 10px;
          }
        }
      }

      .column-text {
        padding: 20px 40px 30px;

        >a {
          padding: 20px 40px 30px !important;
        }
      }
    }

    .listing-group {
      padding: 0;
      margin-bottom: 0 !important;

      .listing-item {
        height: calc(50% - 5px);
        position: relative;
        width: 100%;
        margin-bottom: 5px;
        min-height: 49vh;
      }
    }

    .listing-grid-1,
    .listing-grid-2,
    .listing-grid-3,
    .listing-grid-6 {
      >div.listing-item {
        >a {
          top: auto;
          height: 33%;
          padding: 15px;
          line-height: 1;
          text-align: left;
          text-shadow: none !important;

          &:before {
            top: -200%;
            bottom: 0;
            content: "";
            display: block;
            left: 0;
            position: absolute;
            right: 0;
          }

          &:after {
            bottom: 15px;
            content: 'View details';
            position: absolute;
            display: block;
            font-weight: 300;
            font-size: 13px;
          }

          &:hover {
            background-color: inherit !important;

            &:after {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .listing-grid-4,
    .listing-grid-5 {
      flex-flow: row wrap;
    }

    .listing-grid-5 {
      >div.listing-item {
        min-height: 25vh;
        min-width: 45%;
      }
    }

    .listing-grid-6 {
      flex-flow: row wrap;

      @media (min-width: $screen-md-min) {
        >div.listing-intro {
          max-width: 34%;
        }
      }

      >div {
        min-height: 35rem;
      }

      >div.listing-item {
        min-width: 32% !important;
        flex: 1 1 auto !important;
      }
    }
  }

  .post_list,
  .post-list {
    margin-top: 0 !important;
    display: flex;
    flex-flow: column wrap;

    .post-item {
      margin-bottom: 15px;
      padding-bottom: 15px;
      margin-top: 10px;
      display: flex;
      flex-flow: column wrap;
      flex: 1 1 auto;

      .post-meta {
        margin-top: 15px;

        >div {
          display: inline-block;
          margin-right: 20px;
        }
      }

      &.type-post,
      &.type-project,
      &.type-resource,
      &.type-place,
      &.type-volunteer {
        background-color: $e-light-grey;
        padding: 15px;
      }

      h2 {
        margin-bottom: 10px !important;
      }

      h2, h3 {
        margin-top: 0px !important;
      }

      small {
        color: opacity(0.8);
      }

      p:last-child,
      .post-extra p {
        margin-bottom: 10px;
      }

      .post-image {
        flex: 0 1 25%;
        overflow: hidden;
        background-color: white;
        background-position: 50% 45%;
        background-repeat: no-repeat;
        background-size: cover;
        height: 25vh;
        min-height: 230px;
        max-height: 330px;
        display: block;
        margin-right: 15px;
        margin-bottom: 15px;
      }

      .post-text {
        width: 100%;

        small a {
          text-transform: uppercase;
        }
      }
    }

    &.post-type-project {
      flex-flow: row wrap;

      .post-item {
        flex-flow: column wrap;

        .post-image {
          flex: 1 1 auto;
          min-height: 300px;
        }

        .post-text {
          flex: 1 1 auto;
        }

        .post-description {
          display: none;
        }
      }
    }

    &.post-type-partner {
      .post-item {
        .post-image {
          background-size: contain;
          background-origin: content-box;
          padding: 10px 15px 0 0;
          background-position: top center;
        }
      }
    }

    &.grid-style {
      flex-flow: row wrap;
      justify-content: space-between;

      .post-item {
        display: flex;
        flex-flow: column;
        flex: 1 1 auto;

        .post-text {
          flex: 1 1 auto;
          width: 100% !important;

          .entry-meta {
            display: none;
          }
        }
      }
    }
  }

  .post-feed {
    .post-item {
      margin-top: 5px;
      margin-bottom: 5px;
      padding-top: 5px;
      padding-bottom: 5px;

      h2,
      h3 {
        margin-top: 0;
      }

      p {
        margin-bottom: 5px !important;
      }
    }
  }

  .events-tabs {
    .nav-tabs {
      >li {
        >a {
          font-weight: 600;
          border-left: 1px sol id #ddd;
          border-right: 1px solid #ddd;
          border-top: 1px solid #ddd;
          border-radius: 0;
          margin-right: 4px;
          text-transform: uppercase;
          color: $grey;
          background-color: $v-light-grey;
        }

        &.active {

          >a,
          a:hover,
          a:focus {
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;
            border-top: 1px solid #ddd;
            border-radius: 0;
            color: white;
            background-color: var(--brand-accent);
          }
        }
      }
    }

    .tab-content {
      margin-top: 20px;

      .event-list {
        @extend .post_list;

        article {
          .col-md-12 {
            border: 0px none !important;
            padding-top: 0 !important;
            padding-bottom: 0 !important;
          }

          margin-bottom: 15px;

          h3 {
            margin-top: 0;
          }
        }
      }
    }
  }

  .gform_wrapper {
    form {
      margin-bottom: 40px !important;
    }

    ul.gfield_checkbox li input[type="checkbox"]:checked+label,
    ul.gfield_radio li input[type="radio"]:checked+label {
      font-weight: normal;
    }
  }
}

.fts-mashup img {
  max-width: 300px;
}

.page-subnav {
  clear: both;

  >ul {
    list-style: outside none none;
    padding: 0;

    >li {
      >a {
        &::after {
          content: "";
          font-family: fontawesome;
          position: absolute;
          right: 10px;
          color: $light-grey;
          z-index: 99;
          opacity: 0.5;
        }
      }

      &:first-child {
        >a {
          border-top: 1px solid $v-light-grey;
        }
      }
    }
  }

  .children {
    display: none;
    list-style: outside none none;
    padding: 0;

    li {
      @extend .small;

      a {
        padding: 8px 30px 8px 38px;
      }
    }
  }

  li {
    position: relative;
    margin-bottom: 2px;

    a {
      background-color: $v-light-grey;
      color: $grey;
      display: block;
      padding: 10px 30px;
    }

    a:hover {
      background-color: var(--brand-primary);
      color: white;
      text-decoration: none;
    }
  }

  li.current_page_item {
    li {
      margin-bottom: 0px;
    }

    >a {
      background-color: var(--brand-primary);
      text-decoration: underline;
      color: white;

      &::after {
        content: '';
        opacity: 1;
        color: white;
      }
    }
  }

  .current_page_ancestor,
  .current_page_item {
    >.children {
      display: block;
    }
  }
}


.main-width {
  @extend .col-md-9;
  @extend .clearfix;
  margin-bottom: 30px;

  .bg-colour-pad {
    padding: 30px;
  }
}

.full-width {
  @extend .clearfix;

  .full_width_image {
    div[style*="url("] {
      height: 55vh;
    }
  }

  .col-md-6,
  .col-md-12 {
    @extend .special-padding;
  }
}

.full-indent-width {
  min-height: 1px;
  position: relative;
  margin-bottom: 15px;
  padding-left: 15px;
  padding-right: 15px;

  .aside-width {
    display: none;
  }

  &.page-search {
    margin-top: 40px;
  }
}

.full-width,
.full-indent-width {
  .columns {
    .row {
      min-width: calc(100% + 30px);
    }

    .column-text {
      h3 {
        font-size: 32px;
      }
    }
  }
}

.aside-width {
  @extend .col-md-3;
  @extend .clearfix;
  margin-bottom: 30px;

  h3,
  h4 {
    text-transform: uppercase;
  }

  .aside-block {
    margin-bottom: 40px;
    overflow-wrap: break-word;

    img {
      max-width: 100% !important;
    }
  }
}

.aside-block {
  background-color: $e-light-grey;
  padding: 25px 15px 20px;
  margin-bottom: 10px;

  h4:first-of-type {
    text-transform: uppercase;
    margin-top: 0;
  }

  > ul {
    margin-bottom: 30px;
  }

  ul {
    list-style: none;
    padding: 0;

    li {
      margin-bottom: 5px;

      ul {
        padding: 0.5rem 0 1rem 1rem;

        li {
          font-size: 1.5rem;
          line-height: 1.2;

          ul {
            padding: 0 0 0 2rem;
            margin-bottom: 1.5rem;
          }
        }
      }
    }
  }

  &.related-tags {
    a {
      text-transform: capitalize;
    }
  }
}

.tt-menu {
  background-color: var(--brand-primary);
  width: 100%;
}

.tt-menu.tt-open {
  z-index: 9999 !important;
}

.tt-selectable {
  display: block;
  cursor: pointer;
  padding: 5px 15px;
  color: $white;

  strong {
    font-weight: 300 !important;
  }
}

.tt-selectable:hover {
  background-color: var(--brand-primary);
  color: $white;
}

button,
input,
select,
textarea,
.dropdown-menu {
  border-radius: 0 !important;
}


.feat-data {
  background-color: rgba(0, 0, 0, 0.5);
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  padding: 10px 0 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-content: center;
  flex-flow: column;
  transition: all 0.5s;

  &::before {
    color: $white;
    content: "FEATURED SPECIES";
    display: block;
    font-family: "PT Sans", sans-serif;
    font-size: 13px;
    margin: 10px 0;
    text-align: center;
  }

  >h3 {
    color: $white;
    font-weight: normal;
    line-height: 175%;
    text-transform: uppercase;

    >small {
      color: $white;
      display: block;
      text-transform: none;
      font-size: 85%;
    }
  }

  .feat-links {
    margin-top: 10px;

    >p {
      margin: 0 0 5px;
    }
  }
}

/* ICON LINK */
a.icn-link {
  background-color: var(--brand-primary);
  color: $white;
  border-radius: 15px 4px 4px 15px;
  padding: 3px 10px 3px 2.25em;
  text-shadow: none;
  display: inline-block;
  position: relative;

  &:before {
    background-color: white;
    border-radius: 50%;
    content: "";
    display: block;
    position: absolute;
    left: 2px;
    width: 26px;
    top: calc(50% - 13px);
    height: 26px;
  }

  &:after {
    content: '';
    font-family: fontawesome;
    color: $text-colour;
    display: block;
    position: absolute;
    left: 2px;
    top: calc(50% - 13px);
    width: 26px;
    line-height: 26px;
    font-size: 18px;
    text-align: center;
  }

  &:active,
  &:focus,
  &:hover {
    background-color: $white;
    color: var(--brand-primary);
    text-decoration: none;
    transition: all 0.2s linear 0s;

    &:before {
      background-color: var(--brand-primary);
      transition: all 0.2s linear 0s;
    }

    &:after {
      color: white;
      transition: all 0.2s linear 0s;
    }
  }

  &.icn-map {
    &:after {
      content: '';
    }
  }

  &.icn-top {
    color: $grey;
    background-color: transparent;

    &:before {
      background-color: $text-colour;
    }

    &:after {
      content: '';
      color: white;
    }
  }
}

.breadcrumb-container {
  background-color: #f5f5f5;
  margin-bottom: 20px;

  .breadcrumb {
    background-color: transparent;
    margin-bottom: 0;
    padding: 10px 5vw;
    @extend .small;
  }
}

.cta-home {
  align-self: flex-end;
  flex: 0 1 auto;
  width: 100%;

  .cta {
    list-style: outside none none;
    margin: 0;
    padding: 0;

    .menu-item {
      i {
        font-size: 1.25em;
      }

      h3 {
        color: $white;
        display: block;
        margin: 0;
        text-align: center;
        text-transform: uppercase;
        pointer-events: none;
        padding: 5px 0;
        font-size: 20px;
      }

      a {
        min-height: 110px;
        display: flex;
        flex-flow: column nowrap;
        align-content: center;
        justify-content: space-around;
        background-color: $grey;
        border-top: 1px solid $white;
        color: #fff;
        font-weight: 300;
        text-align: center;
        padding: 5px 0;
        font-size: 22px;
        position: relative;

        &:focus,
        &:hover {
          text-decoration: none;
          background-color: var(--brand-primary);
          text-shadow: 0 1px 2px rgba(0, 0, 0, 0.50);
        }
      }

      small {
        color: $white;
        display: block;
        text-transform: none;
        line-height: 1.3;
        font-size: 17px;
      }
    }
  }
}

.info-panel,
.full-info-panel {
  background-position: center center;
  background-size: cover;
  color: #fff;
  min-height: 400px;
  padding-left: 30px;
  padding-right: 30px;
  position: relative;
  overflow: hidden;
  margin-top: 10px;
}

.full-sponsor-panel {
  min-height: 0;
}

.info-panel .link-block {
  position: absolute;
  display: block;
  bottom: 15px;
}

.info-panel .col-md-6:first-child p {
  font-size: 110%;
}

.info-panel p {
  margin-top: 20px;
}

.full-panel {
  text-align: center;
}

.full-info-panel {
  padding: 30px 20%;
}

.full-panel h2,
.full-panel h2 {
  text-transform: uppercase;
  text-align: center;
  text-size: 34px;
}

.full-info-panel p {
  font-size: 1.3em;
  margin: 10px 0 20px;
}


.data-posts {
  display: flex;
  flex: 0 1 auto;
  flex-flow: column nowrap;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
  align-content: center;
  text-align: center;

  >p {
    margin: 0 auto 10px;
    width: calc(100vw - 120px);
  }

  .post {
    margin-top: 20px;
    text-align: left;

    .post-img {
      background-image: url('/wp-content/themes/livinglandscapes/dist/images/default-project.png');
      background-position: 50% 65%;
      background-size: cover;
      height: 30vh;
      max-height: 250px;
      min-height: 150px;
      position: relative;
    }

    .post-copy {
      padding: 10px 0;
      display: flex;
      flex-flow: column nowrap;
      min-height: 150px;

      >p {
        flex: 1 1 auto;
      }

      >h3 {
        flex: 0 1 auto;
      }
    }
  }

  .posts-carousel {
    margin: 0 auto;
    width: 85%;

    .item {
      min-height: 0 !important;

      .post {
        padding: 15px;
      }
    }

    .cloneditem-1,
    .cloneditem-2,
    .cloneditem-3 {
      display: none;
    }

    .carousel-control {
      .fa {
        background-color: $grey;
        border-radius: 50%;
        height: 30px;
        line-height: 30px;
        position: absolute;
        top: -40px;
        width: 30px;
      }

      &.right {
        right: -7.5%;
      }

      &.left {
        left: -7.5%;
      }

      &::after {
        color: #fff;
        font-size: 1.5em;
        background: #555 none repeat scroll 0 0;
        border-radius: 50%;
        color: hsla(0, 0%, 100%, 0.2);
        display: block;
        font-family: fontawesome;
        height: 30px;
        line-height: 1;
        position: absolute;
        top: calc(50% - 50px);
        width: 30px;
      }
    }
  }

  .block-buttons {
    margin-bottom: 30px;
  }
}

footer {
  .footer-bottom {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .footer-nav {
    list-style: none;
    padding-left: 0px;

    >li {
      border-bottom: 1px dotted $white;
      padding-bottom: 10px;

      &:last-child {
        border-bottom: 0px none;
      }

      >a {
        //text-transform: uppercase;
        font-weight: 500;
        //display: block;
      }
    }

    ul {
      padding-left: 0;
      list-style: none;

      a,
      a:visited {
        color: white;
      }
    }
  }

  .sponsors {
    //background-color: rgba($v-light-grey,0.35);
    min-height: 25vh;
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 40px;
    justify-content: space-around;

    .sponsor-links {
      @extend .container;

      display: flex;
      flex-flow: row wrap !important;
      justify-content: space-between;
      align-content: space-between;

      a {
        flex: 1 1 16%;
        text-align: center;
        margin: 20px auto 20px;
        display: flex;
        justify-content: center;  

        img {
          max-height: 80px !important;
          width: auto !important;
        }
      }
    }
  }

  .social-links {
    padding: 17px 0 0;

    >a {
      display: inline-block;

      >span {
        display: none;
      }
    }
  }
}

.back-to-top {
  color: $grey;
  padding: 20px 15px;
  display: inline-block;
}

.twitter-typeahead {
  display: block !important;
  postiion: static !important;
}

.image-caption {
  height: 50px;
  width: 50px;
  bottom: 0px;
  right: 0px;
  position: absolute;
  display: table;
  vertical-align: middle;
  table-layout: fixed;
  z-index: 900;

  >a {
    color: rgba(255, 255, 255, 0.5) !important;
    height: 50px;
    width: 50px;
    line-height: 35px;
  }

  >div {
    display: none;
    min-height: 50px;
    width: 100%;
    padding: 5px 15px 5px 50px;
    overflow: hidden;
  }

  &:hover,
  &:focus {
    width: 100%;

    .btn-photo-info {
      pointer-events: none;
      color: white;

      i {
        pointer-events: none;
      }
    }

    >a {
      position: absolute;
      bottom: 0;
      right: 0;
    }

    >div {
      z-index: 999;
      opacity: 0;
      transition: opacity 0.5s;
      display: table-cell;
      color: white;
      vertical-align: middle;

      &:hover,
      &:focus {
        opacity: 1;
        transition: opacity 0.5s;
        background-color: rgba(0, 0, 0, 0.5);
      }
    }
  }
}

.main-container,
footer {
  .btn {
    &:after {
      content: '';
      font-family: fontawesome;
      margin-left: 10px;
      font-weight: 300 !important;
    }
  }
}

.fc-day-grid-event,
.fc-content {
  white-space: normal !important;
}

/*Make Bootstrap columns equal height if add .is-flex*/
.is-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 100%;
}

.is-flex>[class*='col-'] {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}

.resource-grid {
  .resource {
    display: flex;
    flex-flow: column wrap;
    justify-content: space-between;
    min-height: 45rem;
    margin-bottom: 2rem;

    &,&:hover,&:active {
      text-decoration: none !important;
    }

    .resource-img{
      flex: 1 1 auto;
      background-size: cover;
      background-position: center center;
    }

    .resource-title{
      flex: 0 1 auto;
      min-height: 10rem;
      padding: 1rem;
    }
  }
}


@media all and (min-width: 768px) {

  .posts-carousel .carousel-inner>.active.left,
  .posts-carousel .carousel-inner>.prev {
    left: -50%;
  }

  .posts-carousel .carousel-inner>.active.right,
  .posts-carousel .carousel-inner>.next {
    left: 50%;
  }

  .posts-carousel .carousel-inner>.left,
  .posts-carousel .carousel-inner>.prev.right,
  .posts-carousel .carousel-inner>.active {
    left: 0;
  }

  .posts-carousel .carousel-inner .cloneditem-1 {
    display: block;
  }
}

@media all and (min-width: 768px) and (transform-3d),
all and (min-width: 768px) and (-webkit-transform-3d) {

  .posts-carousel .carousel-inner>.item.active.right,
  .posts-carousel .carousel-inner>.item.next {
    -webkit-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0);
    left: 0;
  }

  .posts-carousel .carousel-inner>.item.active.left,
  .posts-carousel .carousel-inner>.item.prev {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
    left: 0;
  }

  .posts-carousel .carousel-inner>.item.left,
  .posts-carousel .carousel-inner>.item.prev.right,
  .posts-carousel .carousel-inner>.item.active {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    left: 0;
  }
}

@media all and (min-width: 992px) {

  .posts-carousel .carousel-inner>.active.left,
  .posts-carousel .carousel-inner>.prev {
    left: -33.3%;
  }

  .posts-carousel .carousel-inner>.active.right,
  .posts-carousel .carousel-inner>.next {
    left: 33.3%;
  }

  .posts-carousel .carousel-inner>.left,
  .posts-carousel .carousel-inner>.prev.right,
  .posts-carousel .carousel-inner>.active {
    left: 0;
  }

  .posts-carousel .carousel-inner .cloneditem-2,
  .posts-carousel .carousel-inner .cloneditem-3 {
    display: block;
  }

  .posts-carousel .carousel-control {
    width: 5%;
  }
}

@media all and (min-width: 992px) and (transform-3d),
all and (min-width: 992px) and (-webkit-transform-3d) {

  .posts-carousel .carousel-inner>.item.active.right,
  .posts-carousel .carousel-inner>.item.next {
    -webkit-transform: translate3d(33.3%, 0, 0);
    transform: translate3d(33.3%, 0, 0);
    left: 0;
  }

  .posts-carousel .carousel-inner>.item.active.left,
  .posts-carousel .carousel-inner>.item.prev {
    -webkit-transform: translate3d(-33.3%, 0, 0);
    transform: translate3d(-33.3%, 0, 0);
    left: 0;
  }

  .posts-carousel .carousel-inner>.item.left,
  .posts-carousel .carousel-inner>.item.prev.right,
  .posts-carousel .carousel-inner>.item.active {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    left: 0;
  }
}

/* media queries */
@media (min-width: $screen-sm-min) {

  .banner .container,
  .banner .container-fluid {
    background-color: transparent;
  }

  .cta-home {
    .cta {
      .menu-item {
        a {
          float: left;
        }

        a:nth-child(odd) {
          margin-right: 1px;
          width: calc(50% - 1px);
        }

        a:nth-child(even) {
          width: 50%;
        }
      }
    }
  }

  .content-blocks {
    .call_to_action {
      .cta-blocks {
        .cta-content {
          background-size: cover;
          background-position: center center;
          background-repeat: no-repeat;
          min-height: 250px;

          .cta-text-copy {
            padding: 0px;
          }
        }
      }
    }

    .expanding_text,
    .main-width .text {
      padding-left: 0px;
      padding-right: 0px;
    }
  }

  .carousel-caption p {
    font-size: 32px;
  }

  .feat-links {
    text-align: center;
  }

  .data-posts .full-panel {
    padding: 0 20%;
  }

  .brand {
    margin: 7px 15px 4px;
  }

  .main-search input {
    font-size: 18px;
  }

  footer {
    .sponsors {
      .sponsor-links {
        flex-flow: row nowrap;

        a {
          img {
            max-height: 125px;
          }
        }
      }
    }
  }

  .content-blocks {
    .carousel {
      .show-thumbnails {
        .carousel-thumbnails {
          >a {
            flex: 1 1 32%;
          }
        }
      }
    }
  }
}

@media (min-width: $screen-md-min) {

  .main-nav+.breadcrumb-container {
    margin-top: 52px;
  }

  .main-search-container {
    bottom: 22vh;
  }

  .special-padding{
    padding: 4vw 5vw;
  }

  .navbar-primary.affix {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 900;
  }

  .navbar-collapse {
    padding-left: 5vw;
    padding-right: 5vw;
  }

  .navbar-primary {
    background-color: var(--brand-primary-dark-rgba);

    >.nav {
      display: block;

      >.menu-item.open:last-child {
        >.dropdown-menu {
          right: 0;
          left: auto;
        }
      }
    }

    .social-media {
      float: right;

      a {
        background-color: #fff;
        border-radius: 50%;
        display: inline-block;
        font-size: 18px;
        height: 30px;
        line-height: 30px;
        margin: 10px 2px;
        text-align: center;
        width: 30px;
      }
    }
  }

  .banner {
    .carousel {
      .carousel-inner {
        .item {
          .carousel-caption {
            h1 {
              font-size: 3em;
            }

            p {
              font-size: 1.5em;
            }

            h1,
            p {
              //text-shadow: none;
            }

          }
        }
      }
    }

    .image-caption {
      >a {
        color: white;
      }
    }
  }

  .content-blocks {

    img.alignleft,
    img.alignright {
      max-width: 65%;
    }

    .row {
      .full-indent-width {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .full_width_image {
      div[style*="url("] {
        .btn-photo-info {
          display: block;
          bottom: 15px;
          left: 15px;
          right: auto;
          width: 20px;
          height: 20px;
          line-height: 20px;
          font-size: 1em;
          z-index: 999;
          pointer-events: none;

          &:hover,
          &:focus {
            border-radius: 100%;
          }
        }
      }
    }

    .feature_panel {
      .columns {
        flex-wrap: nowrap;
      }
    }

    .call_to_action {
      .cta-blocks {
        flex-direction: row;

        &.cta-individual {
          .cta-content {
            margin-bottom: 0px;

            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-right: 0;
            }
          }
        }
      }

      .flex-map {
        position: absolute !important;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }

    .carousel {
      .show-thumbnails {
        margin-left: auto;
        margin-right: auto;

        .carousel-thumbnails {
          >a {
            border: 1px solid white;
            flex: 1 1 auto;
            max-width: 34%;
            min-width: 20%;

            .thumbnail {
              min-height: 25vh;
              margin-bottom: 0;
            }
          }
        }
      }
    }

    .height-auto {
      min-height: auto !important;
    }

    &.common-blocks {
      margin-top: 30px;

      >div {
        margin-bottom: 0;
      }
    }
  }

  .cta-home {
    bottom: 0;
    height: 150px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 150;

    .cta {
      .menu-item {
        a {
          min-height: 150px;
          padding: 10px 0;
          background-color: $rgba-black;
          margin: 0 2px;
          border-top: 0px none;
          z-index: 300;
          width: calc(25% - 3px) !important;
          float: left;
        }

        i {
          font-size: 1.75em;
        }

        h3 {
          font-size: 24px;
          padding: 0;
        }
      }

      li:last-child a {
        margin-right: 0;
      }

      li:first-child a {
        margin-left: 0;
      }
    }
  }

  .content-blocks {
    .row {
      .full-indent-width {
        padding-left: 15px;
        padding-right: 15px;
      }
    }

    .full_width_image {
      div[style*="url("] {
        .btn-photo-info {
          display: block;
          bottom: 15px;
          left: 15px;
          right: auto;
          width: 20px;
          height: 20px;
          line-height: 20px;
          font-size: 1em;
          z-index: 999;
          pointer-events: none;

          &:hover,
          &:focus {
            border-radius: 100%;
          }
        }
      }
    }

    .post_list,
    .post-list {
      .post-item {
        flex-flow: row wrap;

        .post-text {
          flex: 1 1 70%;
        }

        .post-image+.post-text {
          width: calc(75% - 15px);
        }
      }

      &.post-type-project {
        .post-item {
          width: 33%;
          flex-shrink: 0;
          flex-grow: 0;
        }

        .post-item.post-featured {
          min-width: 50%;
          flex-shrink: 0;
          flex-grow: 1;
        }
      }

      &.grid-style {
        .post-item {
          flex: 0 1 49%;
        }
      }
    }

    #post-list,
    .post-list,
    .post-item,
    .project,
    .type-project {
      .post-type-project {
        .post-text {
          width: calc(35% - 15px);
        }
      }
    }

    #post-list,
    .post-list,
    .post-item,
    .project,
    .type-project {
      .post-type-post {
        .post-text {
          width: calc(35% - 15px);
        }
      }
    }
  }

  footer {
    .footer-nav {
      display: flex;
      flex-flow: column wrap;

      >li {
        //flex: 0 0 50%;
        padding: 0 20px 0 0;
        border-bottom: 0px none;
        padding-bottom: 0;

        ul li {
          display: inline-block;
        }
      }
    }

    .copyright {
      margin: 40px 0 10px;

      >p,
      .footer-nav2 {
        display: inline-block;
      }
    }

    .footer-nav2 {
      border-left: 1px solid #fff;
      line-height: 1;
      margin: 0 0 0 30px;
      padding: 0 0 0 20px;
    }
  }

  .modal {
    text-align: center;

    &:before {
      display: inline-block;
      vertical-align: middle;
      content: " ";
      height: 100%;
    }

    .modal-dialog {
      display: inline-block;
      text-align: left;
      vertical-align: middle;
    }
  }

  .splash,
  .splash .carousel-inner {
    min-height: 55vh;
  }

  .home {

    .splash,
    .splash .carousel-inner {
      min-height: 85vh;
    }
  }

  .navigation {
    background-color: transparent;
    position: relative;

    .navbar.affix-nav {
      position: absolute;
      top: 40px;
      width: 100%;
      z-index: 0;
    }

    .navbar-apex {
      .navbar-nav {
        margin-left: 205px;
      }
    }

    .brand {
      display: block;
      float: left;
      height: 45px;
      margin: 0 30px 0 0;
      overflow: visible;
      padding: 0;
      position: relative;
      width: 180px;

      img {
        border-color: #fff;
        border-image: none;
        border-style: solid;
        border-width: 15px 10px 10px;
        display: block;
        height: auto;
        left: 0;
        position: absolute;
        top: -40px;
        width: 180px;
        z-index: 99;
        max-height: auto;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.15);
      }
    }
  }

  .carousel-caption p {
    font-size: 38px;
  }

  .sighting {
    border: 0px none;
  }

  .navbar-primary {
    a {
      font-size: 14px;
    }

    .navbar-nav>li>a {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

@media (min-width: $screen-lg-min) {
  .carousel-caption p {
    font-size: 42px;
  }

  .sighting {
    border-bottom: 1px dotted #ccc;
  }

  footer {
    .footer-nav {
      flex-flow: column wrap;
      height: 15rem;

      >li {
        ul li {
          display: block;
        }
      }
    }
  }

  .navigation {
    .brand {
      width: 250px;

      img {
        border-width: 20px 12px 12px;
        width: 250px;
      }
    }
  }

  .navigation .navbar-apex .navbar-nav {
    margin-left: 280px;
  }

  .navbar-primary {
    a {
      font-size: 1em;
    }

    .navbar-nav>li>a {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .banner {
    .carousel {
      .carousel-inner {
        .item {
          .carousel-caption {
            h1 {
              max-width: 60%;
            }

            .carousel-description {
              max-width: 60%;
            }
          }
        }
      }
    }
  }

}

@media (min-width: 1440px) {
  .container {
    width: 1400px;
  }
}

.read-more {
  margin-top: 20px;
}

.content-blocks .carousel .show-thumbnails .carousel-thumbnails>a {
  -moz-box-flex: 1;
  border: 1px solid white;
  flex: 1 1 auto;
  max-width: 34%;
  min-width: 20%;
}

.dropdown {
  position: relative;
}

.dropdown > input[type="checkbox"] {
  position: absolute;
  left: -100vw;
}

.dropdown > label,
.dropdown > a[role="button"] {
  display: inline-block;
  padding: 6px 15px;
  color: #333;
  line-height: 1.5em;
  text-decoration: none;
  border: 1px solid #8c8c8c;
  cursor: pointer;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  width: 100%;
}

.dropdown > label:hover,
.dropdown > a[role="button"]:hover,
.dropdown > a[role="button"]:focus {
  border-color: #333;
}

.dropdown > label:after,
.dropdown > a[role="button"]:after {
  content: "\f0d7";
  font-family: FontAwesome;
  position: absolute;
  right: 15px;
}

.dropdown > ul {
  z-index: 999;
  display: none;
  border: 1px solid #8c8c8c;
  background: #fff;
  padding: 6px 0;
  margin: 0;
  list-style: none;
  width: auto;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  -moz-box-shadow: 0 3px 8px rgba(0,0,0,.15);
  box-shadow: 0 3px 8px rgba(0,0,0,.15);
  margin-bottom: 15px;
}

.resources_filters.dropdown.open ul {
    display: block;
}

.dropdown > ul a {
  display: block;
  padding: 6px 15px;
  text-decoration: none;
  color: #333;
}

.dropdown > ul a:hover,
.dropdown > ul a:focus {
  background: #ececec;
}

.dropdown > input[type="checkbox"]:checked ~ ul,
.dropdown > ul:target {
  left: 0;
}

.dropdown > [type="checkbox"]:checked + label:after,
.dropdown > ul:target ~ a:after {
  content: "\f0d8";
}

.dropdown a.close {
  display: none;
}

.dropdown > ul:target ~ a.close {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  text-indent: -100vw;
  z-index: 1000;
}
